var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm._m(0),
    _c("div", { staticClass: "row justify-center" }, [
      _c(
        "div",
        { staticClass: "col-12 col-md-4 q-mr-sm" },
        [
          _c("q-select", {
            attrs: {
              dense: "",
              outlined: "",
              options: _vm.options_anno,
              label: "Anno di creazione",
            },
            model: {
              value: _vm.anno_creazione,
              callback: function ($$v) {
                _vm.anno_creazione = $$v
              },
              expression: "anno_creazione",
            },
          }),
          _c("br"),
          _c("q-input", {
            attrs: { dense: "", outlined: "", label: "Nominativo Contraente" },
            model: {
              value: _vm.nominativo_cliente,
              callback: function ($$v) {
                _vm.nominativo_cliente = $$v
              },
              expression: "nominativo_cliente",
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "col-12 col-md-4", attrs: { align: "center" } },
        [
          _c("q-input", {
            attrs: { dense: "", outlined: "", label: "Numero pratica" },
            model: {
              value: _vm.numero_pratica,
              callback: function ($$v) {
                _vm.numero_pratica = $$v
              },
              expression: "numero_pratica",
            },
          }),
          _c("br"),
          _c("q-btn", {
            attrs: { color: "primary", label: "Cerca" },
            nativeOn: {
              click: function ($event) {
                return _vm.onCercaFatture.apply(null, arguments)
              },
            },
          }),
        ],
        1
      ),
      _vm._m(1),
    ]),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: !_vm.is_loading,
            expression: "!is_loading",
          },
        ],
        staticClass:
          "text-center justify-center q-pa-md q-gutter-sm fixed-center",
        staticStyle: { "z-index": "1000" },
      },
      [_c("q-spinner-hourglass", { attrs: { size: "4em" } })],
      1
    ),
    _c(
      "div",
      { staticClass: "col-12 col-md-12" },
      [
        _c("q-table", {
          attrs: {
            title: "",
            data: _vm.righe,
            columns: _vm.colonne,
            color: "primary",
            "row-key": "name",
            separator: "cell",
            flat: "",
            "wrap-cells": "",
          },
          scopedSlots: _vm._u([
            {
              key: "header",
              fn: function (props) {
                return [
                  _c(
                    "q-tr",
                    { attrs: { props: props } },
                    _vm._l(props.cols, function (col) {
                      return _c(
                        "q-th",
                        {
                          key: col.name,
                          staticStyle: {
                            "font-size": "14px",
                            "border-bottom": "1px SOLID #404040",
                            "background-color": "#ffcc99",
                            color: "#000",
                          },
                          attrs: { props: props },
                        },
                        [_c("strong", [_vm._v(_vm._s(col.label))])]
                      )
                    }),
                    1
                  ),
                ]
              },
            },
            {
              key: "body",
              fn: function (props) {
                return [
                  _c(
                    "q-tr",
                    {
                      attrs: { props: props },
                      nativeOn: {
                        click: function ($event) {
                          return _vm.selezionaRecordFattura(props.row)
                        },
                      },
                    },
                    _vm._l(props.cols, function (col) {
                      return _c(
                        "q-td",
                        { key: col.name, attrs: { props: props } },
                        [_c("strong", [_vm._v(_vm._s(col.value))])]
                      )
                    }),
                    1
                  ),
                ]
              },
            },
            {
              key: "top-right",
              fn: function () {
                return [
                  _c("q-btn", {
                    attrs: {
                      color: "primary",
                      "icon-right": "archive",
                      label: "ESPORTA IN CSV",
                      "no-caps": "",
                    },
                    on: { click: _vm.esportaTabella },
                  }),
                ]
              },
              proxy: true,
            },
          ]),
        }),
      ],
      1
    ),
    _c("div", { attrs: { align: "center" } }, [
      _c("hr"),
      _c("div", { staticClass: "row justify-center" }, [
        _c(
          "div",
          { staticClass: "col-md-4", attrs: { align: "center" } },
          [
            _c("QQButton", {
              attrs: {
                label: "INDIETRO",
                color: "blue-grey",
                icon: "undo",
                size: "md",
              },
              nativeOn: {
                click: function ($event) {
                  return _vm.onIndietroClicked()
                },
              },
            }),
          ],
          1
        ),
      ]),
      _c("br"),
      _c("br"),
      _c("br"),
      _c("br"),
      _c("br"),
      _c("br"),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { attrs: { align: "center" } }, [
      _c("h4", { staticClass: "q-mb-sm" }, [_vm._v("Fatture rifiutate")]),
      _vm._v(
        " Estrae tutte le fatture che sono state rifiutate dall'Agenzia delle Entrate e vanno ricontrollate "
      ),
      _c("br"),
      _c("br"),
      _c("hr"),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "col-12 col-md-12", attrs: { align: "center" } },
      [_c("hr")]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }